import { TextField } from '@nike/eds';
import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  ITransferData,
  TRANSFER_TYPE_OUTSOLE_COMPLETE,
  TRANSFER_TYPE_TOOLING,
} from '../NewTranferInterface';
import {
  errorActionCreator,
  hideErrorActionCreator,
  updateHasBlockingError,
} from '../../../redux/actions/errorActions';
import { validateOnChange } from '../Validation';
import { updateInputTouched } from '../../../redux/actions/inputActions';
import { roles } from '../../common/RolesConstants';

interface FieldProps {
  transferData: any | ITransferData;
  setTransferData: (params: any) => any;
  enableAutoPopulateFields: boolean;
}
const ProductCreationCenter: FC<FieldProps> = ({
  transferData,
  setTransferData,
  enableAutoPopulateFields = true,
}) => {
  const [transferItemData, userRole, isEditMode, error, pccCodesData] =
    useAppSelector(state => [
      state.transferRecordItem.transferItemData.source,
      state.userRole.role,
      state.input.isEditMode,
      state.error,
      state.newTransfer.pccCodes,
    ]);

  const dispatch = useAppDispatch();

  const [productCreationCenterCode, setProductCreationCenterCode] =
    useState<string>('');
  const [techDevelopCode, setTechDevelopCode] = useState<string>('');

  useEffect(() => {
    dispatch<any>(hideErrorActionCreator(['pccCodes', 'outsole']));
    if (pccCodesData !== null) {
      setProductCreationCenterCode(pccCodesData.pccCodes[0]);
      setTechDevelopCode(pccCodesData.tdCodes[0]);
    }

    if (enableAutoPopulateFields) {
      if (pccCodesData?.pccCodes.length === 0 && !isEditMode) {
        if (transferItemData.productCreationCenterCode === undefined) {
          setTransferData({
            ...transferData,
            pccCodes: '',
            tdCode: '',
          });
        } else {
          setTransferData({
            ...transferData,
            pccCodes: transferItemData.productCreationCenterCode,
            tdCode: transferItemData.tdCode,
          });
        }
      } else if (pccCodesData?.pccCodes.length === 0 && isEditMode) {
        setTransferData({
          ...transferData,
        });
      } else {
        setTransferData({
          ...transferData,
          pccCodes: productCreationCenterCode,
          tdCode: techDevelopCode,
        });
        dispatch<any>(updateInputTouched(true));
      }
    }
  }, [pccCodesData, productCreationCenterCode, techDevelopCode]);

  const handleTextInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { name, value } = event.target;
    const validationParams = {
      pccCode: pccCodesData?.pccCodes[0],
      transferData,
    };

    setTransferData({
      ...transferData,
      pccCodes: value,
    });

    dispatch<any>(updateHasBlockingError(false));

    const validation = validateOnChange(
      name,
      value,
      userRole,
      validationParams
    );

    dispatch<any>(errorActionCreator(name, validation.message));

    if (validation.blockingError) {
      dispatch<any>(updateHasBlockingError(true));
    }

    dispatch<any>(updateInputTouched(true));
  };

  return (
    <TextField
      name="pccCodes"
      onChange={handleTextInputChange}
      label="Product Creation Center Code"
      type="text"
      value={transferData.pccCodes}
      disabled={
        !transferData?.transferType?.value ||
        transferData?.transferType?.value === TRANSFER_TYPE_TOOLING ||
        transferData?.transferType?.value === TRANSFER_TYPE_OUTSOLE_COMPLETE ||
        ((userRole === roles.factory || userRole === roles.pmo) &&
          isEditMode &&
          transferItemData.status !== 'Factory' &&
          transferItemData.status !== 'Draft')
      }
      id="product-creation-center-input"
      hasErrors={error.pccCodesError}
      errorMessage={error.pccCodesErrorMessage}
    />
  );
};

export default ProductCreationCenter;
